<template>
  <b-form>
    <b-card title="Müşteri Güncelle">
      <b-row>
        <b-col
          sm="12"
          md="12"
        >
          <b-form-group label="Firma Ünvanı">
            <b-form-input
              v-model="formData.company"
              placeholder="Firma Ünvanı"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="4"
        >
          <b-form-group label="Telefon">
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+9)
              </b-input-group-prepend>
              <cleave
                id="phone"
                v-model="formData.phone"
                class="form-control"
                :raw="false"
                :options="options.phone"
                placeholder="Telefon"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <b-form-group label="Faks">
            <b-input-group>
              <b-input-group-prepend is-text>
                TR (+9)
              </b-input-group-prepend>
              <cleave
                id="fax"
                v-model="formData.fax"
                class="form-control"
                :raw="false"
                :options="options.fax"
                placeholder="Faks"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <b-form-group label="E-Posta">
            <b-form-input
              v-model="formData.email"
              placeholder="E-Posta"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Ek Notlar">
            <b-form-textarea
              v-model="formData.notes"
              placeholder="Ek Notlar"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Fatura Bilgileri">
      <b-row>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group label="Vergi Dairesi">
            <b-form-input
              v-model="formData.tax_office"
              placeholder="Vergi Dairesi"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group label="Vergi No">
            <b-form-input
              v-model="formData.tax_number"
              placeholder="Vergi No"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group label="İl">
            <v-select
              v-model="id_cities"
              label="title"
              :options="cities"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
        >
          <b-form-group label="İlçe">
            <v-select
              v-model="id_districts"
              label="title"
              :options="districts"
              :disabled="id_cities.id == null"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group label="Adres">
            <b-form-textarea
              v-model="formData.address"
              placeholder="Adres"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'

export default {
  name: 'CustomersEdit',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    Cleave,
    BInputGroupPrepend,
    BInputGroup,
  },
  data() {
    return {
      detailForm: false,
      submitStatus: false,
      id_cities: {
        id: null,
        title: null,
      },
      id_districts: {
        id: null,
        title: null,
      },
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
        fax: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      formData: {
        id: null,
        company: null,
        phone: null,
        fax: null,
        email: null,
        tax_office: null,
        tax_number: null,
        address: null,
        notes: null,
        id_cities: null,
        id_districts: null,
      },
    }
  },
  computed: {
    customerData() {
      return this.$store.getters['customers/getCustomer']
    },
    cities() {
      return this.$store.getters['cities/getCities']
    },
    districts() {
      if (this.id_cities.id) {
        return this.$store.getters['districts/getDistricts']
      }
      return []
    },
    saveStatus() {
      const status = this.$store.getters['customers/getCustomerSaveStatus']
      return status
    },
  },
  watch: {
    id_cities(val) {
      if (val.id) {
        this.getDistricts()
      }
    },
    customerData(val) {
      this.setFormData(val)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getCities()
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/customerView', this.$route.params.id)
    },
    setFormData(data) {
      this.formData = data
      this.id_cities = {
        id: data.id_cities,
        title: data.city,
      }
      this.id_districts = {
        id: data.id_districts,
        title: data.district,
      }
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getCities() {
      this.$store.dispatch('cities/citiesList', {
        select: ['cities.id AS id', 'cities.title AS title'],
      })
    },
    getDistricts() {
      if (this.id_cities.id) {
        this.$store.dispatch('districts/districtsList', {
          select: ['districts.id AS id', 'districts.title AS title'],
          where: {
            id_cities: this.id_cities.id,
          },
        })
      }
    },
    submitForm() {
      this.submitStatus = true
      const submitData = this.formData
      submitData.id_cities = this.id_cities.id
      submitData.id_districts = this.id_districts.id
      this.$store.dispatch('customers/customerSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
